import React, { Fragment } from 'react';

const Repere = () => {
    return ( 
        <Fragment>
            <line x1="50" y1="688" 
            x2="50" y2="100" 
            stroke="black" 
            strokeWidth="2"/>

            <line x1="50" y1="100" 
            x2="43" y2="110" 
            stroke="black" 
            strokeWidth="2"/>

            <line x1="50" y1="100" 
            x2="57" y2="110" 
            stroke="black" 
            strokeWidth="2"/>

            <line x1="50" y1="688" 
            x2="920" y2="688" 
            stroke="black" 
            strokeWidth="2"/>

            <line x1="910" y1="681" 
            x2="920" y2="688" 
            stroke="black" 
            strokeWidth="2"/>

            <line x1="910" y1="695" 
            x2="920" y2="688" 
            stroke="black" 
            strokeWidth="2"/>

            <text x="910" y="668" fontSize="30"  fontFamily="Arial" fill="black">N</text>
            <text x="70" y="115" fontSize="30"  fontFamily="Arial" fill="black">Z</text>
        </Fragment>
     );
}
 
export default Repere;