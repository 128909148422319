import React, { Component, Fragment } from 'react';
import Slider1 from "./components/Slider1";
import Slider2 from './components/Slider2';
import Repere from './components/Repere';
import {data} from "./data";

class App extends Component {

  constructor() {
    super();
    this.state = {
      elements: data,
      zoom: 1,
      position_x: 0,
      position_y: 0,
      info: [],
      keypressed : false,
    };
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleOnClick2 = this.handleOnClick2.bind(this);
    this.handleOnClickInfo = this.handleOnClickInfo.bind(this);
    this.handleOnChangeX = this.handleOnChangeX.bind(this);
    this.handleOnChangeY = this.handleOnChangeY.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  componentDidMount(){
      document.addEventListener("keydown",this.handleKeyPress,false);
      document.addEventListener("keyup",this.handleKeyUp,false);
      document.addEventListener('wheel', this.zoom,false);
  }

  componentWillUnmount(){
      document.removeEventListener("keydown",this.handleKeyPress,false);
      document.removeEventListener("keyup",this.handleKeyUp,false);
  }

  handleOnClick(){
    if(this.state.zoom > 1){
      this.setState({
        zoom: this.state.zoom-1
      });
    }
  }

  handleOnClick2(){
    if(this.state.zoom < 24){
      this.setState({
        zoom: this.state.zoom+1
      });
    }
  }

  handleOnClickInfo(event){
      this.setState({
        info: this.state.elements[event.target.className.baseVal]
      })
  }

  handleOnChangeX(x){
    this.setState({
      position_x: x
    })
  }

  handleOnChangeY(y){
    this.setState({
      position_y: y
    })
  }

  affichage(){
    if(this.state.info && this.state.info.length !== 0){

      var col6 = this.state.info[6];
      var txt_d = "";
      var size = "12";
					
      if( this.state.info[3] === "stbl")
      {
        txt_d = "stable";
        size = "16";
      }
      else if( col6.indexOf("B-") > -1 )
      {
        txt_d = "Émission β\u207B";
        size= "16";
      }
      else if( col6.indexOf("B+") > -1 )
      {
        txt_d = "Émission β\u207A";
        size= "16";
      }
      else if(col6.indexOf("EC") > -1 )
      {
        txt_d = "Capture d'électron";
        size= "13";
      }
      else if(col6.indexOf("IT") > -1 )
      {
        txt_d = "Transition interne";
        size= "13";
      }
      else if(col6.indexOf("A") > -1 )
      {
        txt_d = "Émission α";
        size= "16";
      }
      else if(col6.indexOf("e+") > -1 )
      {
        txt_d = "Émission de positron";
        size= "12";
      }
      else if(col6.indexOf("p") > -1 || col6.indexOf("2p") > -1)
      {
        txt_d = "Émission de proton";
        size= "12";
      }
      else if(col6.indexOf("n") > -1 || col6.indexOf("2n") > -1)
      {
        txt_d = "Émission de neutron";
        size= "12";
      }
      else
      {
        txt_d = "Fission spontanée";
        size= "13";
      }
      
      if(col6.indexOf("?") > -1)
      {
        txt_d += "\u0020\u003F";
      }
      
      if(col6.indexOf("p-unst#") > -1  || col6.indexOf("p-unst") > -1 )
      {
        txt_d = "\u0020instable";
      }

      // ----------------------

        var lunite = "";
        var annee = "";
          
        switch(this.state.info[4])
        {
          case "y":
          lunite = "\u0020années";
          break;
          case "ys":
          lunite = "×10\u207B\u00B9\u2074\u0020s";
          break;
          case "ky":
          lunite = "×10\u00B3\u0020année";
          break;
          case "My":
          lunite = "×10\u2076\u0020année";
          break;
          case "Gy":
          lunite = "×10\u2079\u0020année";
          break;
          case "Ty":
          lunite = "×10\u00B9\u00B2\u0020année";
          break;
          case "Py":
          lunite = "×10\u00B9\u2075\u0020année";
          break;
          case "Ey":
          lunite = "×10\u00B9\u2078\u0020année";
          break;
          case "Zy":
          lunite = "×10\u00B2\u00B9\u0020année";
          break;
          case "Yy":
          lunite = "×10\u00B2\u2074\u0020année";
          break;
          case "s":
          lunite = "\u0020s";
          break;
          case "ns":
          lunite = "\u0020ns";
          break;
          case "d":
          lunite = "\u0020jours";
          break;
          case "ms":
          lunite = "\u0020ms";
          break;
          case "m":
          lunite = "\u0020min";
          break;
          case "h":
          lunite = "\u0020heures";
          break;
          case "zs":
          lunite = "×10\u207B\u00B2\u00B9\u0020s";
          break;
          case "as":
          lunite = "×10\u207B\u00B9\u2078\u0020s";
          break;
          case "fs":
          lunite = "×10\u207B\u00B9\u2075\u0020s";
          break;
          case "ps":
          lunite = "×10\u207B\u00B9\u00B2\u0020s";
          break;
          case "us":
          lunite = "×10\u207B\u2076\u0020s";
          break;
          default:
          lunite = "";
        }

        if(txt_d === "stable")
        {
          annee = "";
        }
        else
        {
          annee = this.state.info[3].replace(".",",").replace("#","").replace("* ","").replace("& ","") + lunite;
        }

      // ----------------------

      return (
        <Fragment>
            <text x="115" y="150" fontSize={size} textAnchor="middle" fontFamily="Arial" fontWeight="bold" fill="black" >Décroissance : {txt_d}</text>
            <text x="115" y="178" fontSize="13" textAnchor="middle" fontFamily="Arial" fontWeight="bold" fill="black">Découverte : {this.state.info[5]}</text>
            {
              txt_d !== "stable" ? (
                  <text x="115" y="203" fontSize="13" textAnchor="middle" fontFamily="Arial" fontWeight="bold" fill="black">Demi-Vie : {annee}</text>
              ) : null
            }
        </Fragment>
      );

    }
  }

  handleKeyPress(event){
    event.preventDefault()

    if(this.state.keypressed === false && this.state.position_x >= 0 && this.state.position_x <= 175 && this.state.position_y >= 0 && this.state.position_y <= 117){
      this.buttonPressTimer = setInterval(() => {
        if(event.code === "ArrowLeft"){
            if(this.state.position_x > 0){
              this.setState({
                position_x: this.state.position_x -= 1,
              })
            }      
        }else if(event.code === "ArrowRight"){
            if(this.state.position_x < 175){
              this.setState({
                position_x: this.state.position_x += 1,
              })
            } 
        }else if(event.code === "ArrowUp"){
            if(this.state.position_y > 0){
              this.setState({
                position_y: this.state.position_y -= 1,
              })
            }  
        }else if(event.code === "ArrowDown"){
            if(this.state.position_y < 117){
              this.setState({
                position_y: this.state.position_y += 1,
              })
            } 
        }else if(event.code === "KeyS"){
            if(this.state.zoom < 24){
              this.setState({
                zoom: this.state.zoom+1
              });
            }
        }else if(event.code === "KeyA"){
            if(this.state.zoom > 1){
              this.setState({
                zoom: this.state.zoom-1
              });
            }
        }
      }, 100);
      this.setState({
        keypressed : true,
      })
    }
  }

  handleKeyUp(event){
    event.preventDefault()
    console.log("keyup");
    if(this.state.keypressed === true ){
        clearInterval(this.buttonPressTimer);
        this.setState({
          keypressed : false,
        })
    }
  }

  render(){
    return (
      <div className="svg-container">
        <svg id="container" viewBox="0 0 1250 768" preserveAspectRatio="xMinYMin meet" className="svg-content">
          
          <svg x="0" y="0" width="1250" height="50">
            <rect x="0" y="0"  fill="#333" width="100%" height="100%"/>
            <text x="625" y="25" fontSize="30"  fontFamily="Arial" fontWeight="bold" dominantBaseline="central" textAnchor="middle" fill="white">Diagramme de Segré - Carte (N,Z)</text>
          </svg>
          
          <svg x="1000" y="180" width="230" height="340">
            <rect x="0" y="0"  fill="white" width="100%" height="100%"/>
            <rect x="0" y="0"  fill="#333" width="100%" height="30"/>
            <text x="70" y="22" fontSize="20"  fontFamily="Arial" fontWeight="bold" fill="white">Légende</text>
            <rect x="10" y="40"  fill="red" width="15" height="15"/>
            <text x="40" y="54" fontSize="16"  fontFamily="Arial" fontWeight="bold" fill="black">Noyau stable</text>
            <rect x="10" y="75"  fill="#999900" width="15" height="15"/>
            <text x="40" y="89" fontSize="16"  fontFamily="Arial" fontWeight="bold" fill="black">Capture électronique</text>
            <rect x="10" y="110"  fill="green" width="15" height="15"/>
            <text x="40" y="124" fontSize="16"  fontFamily="Arial" fontWeight="bold" fill="black">Émission α</text>
            <rect x="10" y="145"  fill="yellow" width="15" height="15"/>
            <text x="40" y="159" fontSize="16"  fontFamily="Arial" fontWeight="bold" fill="black">Émission β&#x207a;</text>
            <rect x="10" y="180"  fill="blue" width="15" height="15"/>
            <text x="40" y="194" fontSize="16"  fontFamily="Arial" fontWeight="bold" fill="black">Émission β&#x207b;</text>
            <rect x="10" y="215"  fill="purple" width="15" height="15"/>
            <text x="40" y="229" fontSize="16"  fontFamily="Arial" fontWeight="bold" fill="black">Émission de positron</text>
            <rect x="10" y="250"  fill="#98FB98" width="15" height="15"/>
            <text x="40" y="264" fontSize="16"  fontFamily="Arial" fontWeight="bold" fill="black">Émission de neutron</text>
            <rect x="10" y="283"  fill="orange" width="15" height="15"/>
            <text x="40" y="297" fontSize="16"  fontFamily="Arial" fontWeight="bold" fill="black">Émission de proton</text>
            <rect x="10" y="316"  fill="pink" width="15" height="15"/>
            <text x="40" y="330" fontSize="16"  fontFamily="Arial" fontWeight="bold" fill="black">Fission spontanée</text>
          </svg>
          
          <svg x="1000" y="70" width="230" height="100">
            <rect x="0" y="0"  fill="white" width="100%" height="100%"/>
            <rect x="0" y="0"  fill="#333" width="100%" height="30"/>
            <text x="80" y="22" fontSize="20" fontFamily="Arial" fontWeight="bold" fill="white">Zoom</text>
            
            <svg x="50" y="45" style={{"cursor" : "pointer"}} width="40" height="40" onClick={this.handleOnClick}>
              <rect cx="10" cy="10" fill="#BC7633" strokeWidth="2" stroke="#444" width="100%" height="100%"/>
              <text x="11" y="33" fontSize="50" fontFamily="Arial" fontWeight="bold" fill="black">-</text>
              <rect cx="10" cy="10" opacity="0" width="100%" height="100%"/>
            </svg>
            
            <svg x="130" y="45" style={{"cursor" : "pointer"}} width="40" height="40" onClick={this.handleOnClick2}>
              <rect cx="10" cy="10" fill="#BC7633" strokeWidth="2" stroke="#444" width="100%" height="100%"/>
              <text x="8" y="36" fontSize="45" fontFamily="Arial" fontWeight="bold" fill="black">+</text>
              <rect cx="10" cy="10" opacity="0" width="100%" height="100%"/>
            </svg>           
          </svg>
          
          <svg x="1000" y="530" width="230" height="220">
            <rect x="0" y="0"  fill="white" width="100%" height="100%"/>
            <rect x="0" y="0"  fill="#333" width="100%" height="30"/>
            <text x="17" y="22" fontSize="20"  fontFamily="Arial" fontWeight="bold" fill="white">Nucléide selectionné</text>
            
            <svg x="45" y="40" width="140" height="100">
                {
                   this.state.info && this.state.info.length !== 0 ? (
                      <Fragment>
                          <text x="70" y="50" fontSize={ this.state.info[2].replace(/[0-9]/g, '').length === 2 ? "45" :"55"} fontFamily="Arial" fontWeight="bold" color="black" textAnchor="middle" dominantBaseline="central">{this.state.info[2].replace(/[0-9]/g, '')}</text>
                          <text x="70" y="50" fontFamily="Arial" fontWeight="bold" color="black" textAnchor="end" fontSize="20" dy="-10" dx="-35">{this.state.info[0]}</text>
                          <text x="70" y="50" fontFamily="Arial" fontWeight="bold" color="black" textAnchor="end" fontSize="20" dy="20" dx="-35">{parseInt(this.state.info[1]/10)}</text>
                      </Fragment>
                  ) : null
                }
            </svg>

            {this.affichage()}

          </svg>
          
          <rect x="20" y="70" width="930" height="640"  fill="white"/>
          
          <rect x="20" y="710"  fill="#444" width="930" height="40" strokeWidth="1" stroke="#444"/>
          
          <rect x="950" y="70"  fill="#444" width="40" height="680" strokeWidth="1" stroke="#444"/>
          
          <rect x="955" y="714"  fill="#282828" width="28" height="28" strokeWidth="1" stroke="#282828"/>
          
          <rect x="40" y="728"  fill="#666" width="890" height="4"/>
          
          <rect x="968" y="90"  fill="#666" width="4" height="600"/>
          
          <Slider1 x={this.state.position_x} changeX={this.handleOnChangeX}/>
          <Slider2 y={this.state.position_y} changeY={this.handleOnChangeY}/>

          <svg id="cadre" x="20" y="70" width="930" height="640" onClick={this.handleOnClickInfo}>
          {
              this.state.elements.map((element,i) => {
                let protons = parseInt(element[1])/10;
                let neutrons = element[0]-parseInt(element[1])/10;
                
                let taille_x = this.state.zoom*4.5;
                let taille_y = this.state.zoom*5;

                if( element[1]%10 === 0 && (neutrons-this.state.position_x)*taille_x+32+(taille_x/2) > -1*taille_x && (neutrons-this.state.position_x)*taille_x+32+(taille_x/2) < 930+taille_x && (682-(protons-this.state.position_y+1)*taille_y-66) > -1*taille_y )
                {
                  
                  var color = "";
                  
                  var col6 = element[6];
                      
                  if( element[3] === "stbl")
                  {
                    color = "red";
                  }
                  else if( col6.indexOf("B-") > -1 )
                  {
                    color = "blue";
                  }
                  else if( col6.indexOf("B+") > -1 )
                  {
                    color = "yellow";
                  }
                  else if(col6.indexOf("EC") > -1 )
                  {
                    color = "#999900";
                  }
                  else if(col6.indexOf("IT") > -1 )
                  {
                    color = "#aa8cc5";
                  }
                  else if(col6.indexOf("A") > -1 )
                  {
                    color = "green";
                  }
                  else if(col6.indexOf("e+") > -1 )
                  {
                    color = "purple";
                  }
                  else if(col6.indexOf("p") > -1 || col6.indexOf("2p") > -1)
                  {
                    color = "orange";
                  }
                  else if(col6.indexOf("n") > -1 || col6.indexOf("2n") > -1)
                  {
                    color = "#98FB98";
                  }
                  else
                  {
                    color = "pink";
                  }

                  let nom = element[2].replace(/[0-9]/g, '');
                      
                  return (     
                    <Fragment key={i}>
                        <rect style={{"cursor" : "pointer"}} className={i}  x={(neutrons-this.state.position_x)*taille_x+32} y={682-(protons-this.state.position_y+1)*taille_y-66} width={taille_x} height={taille_y} stroke="white" strokeWidth={taille_x*4/90} fill={color}/>

                        {
                          this.state.zoom >= 2 ? (
                            <Fragment>
                                <text style={{"cursor" : "pointer"}} className={i} textAnchor="middle" dominantBaseline="central" x={(neutrons-this.state.position_x)*taille_x+32+(taille_x/2)} y={680-(protons-this.state.position_y+1)*taille_y-66+(taille_y/2)} fontSize={taille_x*43/90} fontFamily="Arial" fontWeight="bold" fill={(color === "red" || color === "purple" || color === "blue") ? "white" : "black"} >{nom}</text> : null
                        
                                {this.state.zoom >= 5 ? (
                                  <Fragment>
                                      <text style={{"cursor" : "pointer"}} className={i} x={(neutrons-this.state.position_x)*taille_x+32+(taille_x*10/90)} y={680-(protons-this.state.position_y+1)*taille_y-66+(25*taille_y/100)} fontSize={taille_x*15/90} fontFamily="Arial" fontWeight="bold" fill={(color === "red" || color === "purple" || color === "blue") ? "white" : "black" }>{element[0]}</text>
                                      <text style={{"cursor" : "pointer"}} className={i} x={(neutrons-this.state.position_x)*taille_x+32+(taille_x*10/90)} y={680-(protons-this.state.position_y+1)*taille_y-66+(87*taille_y/100)} fontSize={taille_x*15/90} fontFamily="Arial" fontWeight="bold" fill={(color === "red" || color === "purple" || color === "blue") ? "white" : "black" }>{parseInt(element[1]/10)}</text>
                                  </Fragment>
                                ) : null}
                            </Fragment>
                          ) : null
                        }       
                    </Fragment>
                  )
                          
                }
              })
          }
          </svg>
          
          <Repere/>

          <text x="90" y="763" fontSize="11" fontFamily="Arial" fontWeight="bold" fill="#444">auteur: Samuel JACQUINET – Licence Creative Commons Attribution – Pas d’Utilisation Commerciale – Pas de Modification 4.0 International. &nbsp;&nbsp;&nbsp;Provenance des données : Chinese Physics C, 2016, vol. 41 030001</text>
    
        </svg>
    </div>
    );
  }
}

export default App;
