import React,{Component} from 'react';

class Slider2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rect: {
                x: 5, 
                y: 5
            }
        };
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.y !== this.props.y){
            this.setState({
                rect: {
                    x: this.state.rect.x, 
                    y: this.props.y*5+5
                }
            })
        }
    }

    startDrag(event) {
        event.preventDefault();
        let point = this.svg.createSVGPoint();
        if (event.touches) { event = event.touches[0]; }
        point.y = event.clientY;
        point = point.matrixTransform(this.svg.getScreenCTM().inverse());
        this.setState({dragOffset: {
            y: point.y - this.state.rect.y
        }});
        
        const mousemove = (event) => {
          event.preventDefault();
          if (event.touches) { event = event.touches[0]; }
          point.y = event.clientY;
          let cursor = point.matrixTransform(this.svg.getScreenCTM().inverse());
          if(cursor.y - this.state.dragOffset.y > 5 &&  cursor.y - this.state.dragOffset.y < 590){
            this.setState({rect: {
                x: 5,
                y: cursor.y - this.state.dragOffset.y
            }});
            }
        };
        
        const mouseup = (event) => {
          this.props.changeY(Math.round((this.state.rect.y-5)/5));
          document.removeEventListener("mousemove", mousemove);
          document.removeEventListener("mouseup", mouseup);
          document.removeEventListener("touchmove", mousemove);
          document.removeEventListener("touchend", mouseup);
        };
        
        document.addEventListener("touchmove", mousemove);      
        document.addEventListener("touchend", mouseup);
        document.addEventListener('touchleave', mouseup);
        document.addEventListener('touchcancel', mouseup);

        document.addEventListener("mousemove", mousemove);      
        document.addEventListener("mouseup", mouseup);
      }


    render() { 
        return ( 
            <svg x="950" y="70" width="40" height="680" ref={(svg) => this.svg = svg}>
                <rect 
                    x={this.state.rect.x}
                    y={this.state.rect.y}
                    fill="white" 
                    width="30" 
                    height="45"
                    style={{"cursor" : "pointer"}}
                    ref={(e) => this.svgRectElem = e}
                    onMouseDown={(e) => this.startDrag(e, this.svgRectElem)}
                    onTouchStart={(e) => this.startDrag(e, this.svgRectElem)}
                />
	        </svg>
         );
    }
}
 
export default Slider2;